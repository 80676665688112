<template>
  <div class="main">
    <div class="row">
      <div class="inspect-left" v-if="courses">
        <div class="header">
          <div class="title">
            <h4>UC Order {{ localOrder.order }}</h4>
          </div>
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="btn mr-2"
              style="min-width: 5rem;"
              v-b-modal.notes-modal
            >
              <v-icon
                class="whiteIcon mr-2"
                style="width: 1.3rem; height: 1.3rem;"
                name="comment-alt"
              ></v-icon>
              {{ order.admin_notes && order.admin_notes.length > 0 ? " ("+order.admin_notes.length+")" : "" }}
            </b-button>
            <b-button
              variant="primary"
              class="btn"
              style="min-width: 5rem;"
              v-if="($store.state.user.can_view_messages && order.wc_order_id) || ( $store.state.user.can_view_templates)"
              v-b-modal.messages-modal
            >
              <v-icon
                class="whiteIcon mr-2"
                style="width: 1.3rem; height: 1.3rem;"
                name="envelope"
              ></v-icon>
              {{  this.emailCount }}
            </b-button>
          </div>
          
        </div>
        <div class="dateBubbleContainer">
          <div class="dateBubble">
            <span> Ordered: {{ formatDate(localOrder.order_date) }}</span>
          </div>
          <div class="dateBubble" v-if="localOrder.order_status">
            <span> Processed: {{ formatDate(localOrder.uop_processed_date) }}</span>
          </div>
          <div class="dateBubble" v-if="localOrder.refund_status">
            <span> Refunded: {{ formatDate(localOrder.refund_date) }}</span>
          </div>
        </div>
        <div class="header subHeader mt-1 mb-1 p-2" v-if="localOrder.order_status && (checkIfAllowed(['admin', 'uop_admin']) || checkIfPartnerMatch(['CT', 'MT', 'C4T', 'TE']))">
          <div>
            <span>{{cardType}}</span>
          </div>
          Cashnet ID: {{(this.localOrder.cashnet_transactions && this.localOrder.cashnet_transactions.length > 0) ? this.localOrder.cashnet_transactions.map(e => e.id).join(', ') : "N/A"}}
        </div>
        <div class="inspect-fields">
          <div class="row">
            <label for="course-number">Course Number</label>
            <b-form-select
              id="course-number"
              :disabled="localOrder.order_status && !checkIfAllowed( ['admin'] )"
              v-model="selectedCourseId"
              class="inputFieldsSize"
              size="sm"
            >
              <option
                v-for="(course, c) in courses"
                :key="c"
                :value="course._id"
              >
                {{ course.course_number }}
              </option>
            </b-form-select>
          </div>
          <div id="course-title-row" class="row">
            <label for="course-title">Course Title</label>
            <b-form-input
              type="text"
              id="course-title"
              :value="localOrder.course && localOrder.course.course_title"
              disabled
              class="inputFieldsSize"
              size="sm"
            />
          </div>
          <b-tooltip target="course-title-row" triggers="hover">{{
            localOrder.course && localOrder.course.course_title
          }}</b-tooltip>
          <div class="row">
            <label for="course-credits">Course Credits</label>

            <b-input-group prepend="#" class="inputFieldsSize" size="sm">
              <b-form-select
                id="inline-form-input-course_credits"
                v-model="localOrder.course_credits"
                :value="localOrder.course_credits"
                :disabled="localOrder.order_status && !checkIfAllowed( ['admin'] )"
                placeholder=""
              >
                <option
                  v-for="(creditOption, c) in localOrder.course &&
                  localOrder.course.credit_options"
                  :key="c"
                  :value="creditOption"
                >
                  {{ creditOption }}
                </option>
              </b-form-select>
            </b-input-group>
          </div>
          <div class="row" v-if="localOrder.course_credit_fee !== 0">
            <label for="credit-fee">Credit Fee</label>
            <b-input-group prepend="$" class="inputFieldsSize" size="sm">
              <b-form-select
                id="inline-form-input-credit-fee"
                v-model="localOrder.course_credit_fee"
                :value="localOrder.course_credit_fee"
                :disabled="localOrder.order_status && !checkIfAllowed( ['admin'] )"
                placeholder=""
              >
                <option
                  v-for="(creditFeeOption, c) in creditFeeOptions"
                  :key="c"
                  :value="creditFeeOption"
                >
                  {{ creditFeeOption }}
                </option>
              </b-form-select>
            </b-input-group>
          </div>
          <div class="row" v-if="localOrder.course_fixed_credit_fee !== 0">
            <label for="fixed-fee">Fixed Fee</label>
            <b-input-group
              v-model="localOrder.course_fixed_credit_fee"
              prepend="$"
              class="inputFieldsSize"
              size="sm"
            >
              <b-form-input
                id="inline-form-input-fixed-fee"
                :value="localOrder.course_fixed_credit_fee"
                disabled
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="row">
            <label for="total-credit-fee">Total Credit Fee</label>
            <b-input-group prepend="$" class="inputFieldsSize" size="sm">
              <b-form-input
                type="text"
                id="total-credit-fee"
                disabled
                :value="totalCreditFee"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="row" v-if="localOrder.order_status">
            <label for="course-grade">Course Grade</label>
            <select
              id="course-grade"
              v-model="localOrder.course_grade"
              :disabled="
                (localOrder.course_status && this.$store.state.user.user_role !== 'admin') ||
                this.$store.state.user.user_role == 'uop_admin'
              "
              class="inputFieldsSize"
            >
              <option value="A" :disabled="!canLetterGrade">A</option>
              <option value="B" :disabled="!canLetterGrade">B</option>
              <option value="C" :disabled="!canLetterGrade">C</option>
              <option value="P" :disabled="!canPassFailGrade">P</option>
              <option value="I">Incomplete</option>
            </select>
          </div>
          <div class="row" v-if="localOrder.order_status">
            <label for="course-completion-date">
              {{ localOrder.course_grade && localOrder.course_grade == 'I' ? 'Incomplete Date' : 'Completion Date'}}
            </label>
            <b-form-datepicker
              @context="onContext"
              :disabled="
                (localOrder.course_status && this.$store.state.user.user_role !== 'admin') ||
                this.$store.state.user.user_role == 'uop_admin'
              "
              label-no-date-selected=""
              v-model="localOrder.course_completion_date"
              id="datepicker-sm"
              size="sm"
              locale="en"
              class="mb-2 inputFieldsSize"
              placeholder="MM/DD/YY"
              :max="new Date()"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            ></b-form-datepicker>
          </div>
          <div class="row" v-if="localOrder.order_status">
            <label for="grade-type">Grade Type</label>
            <select
              id="grade-type"
              :disabled="
                (localOrder.course_status && this.$store.state.user.user_role !== 'admin') ||
                this.$store.state.user.user_role == 'uop_admin'
              "
              v-model="localOrder.grade_type"
              class="inputFieldsSize"
            >
              <option :value="true">UOP Banner</option>
              <option 
                v-if="typeof $store.state.user.can_grade_insidePacific == 'undefined' || $store.state.user.can_grade_insidePacific === true"
                :value="false"
              >
                Inside Pacific
              </option>
            </select>
          </div>
          <div class="row" v-if="localOrder.customer">
            <label for="course-credits">UOP Student ID</label>

            <b-input-group prepend="#" class="inputFieldsSize" size="sm">
              <b-form-input
                id="inline-form-input-uop_student_id"
                v-model="localOrder.customer.uop_student_id"
                :value="localOrder.customer.uop_student_id"
                type="number"
                :disabled="!checkIfAllowed(['admin', 'uop_admin'])"
              />
            </b-input-group>
          </div>
        </div>
        <div class="button-section" v-if="showButtonSection">
            
            <b-button
              variant="danger"
              class="btn"
              @click="deleteSelectedOrder()"
              v-if="showDeleteButton"
              :disabled="disableDeleteButton"
            >
              Delete Order
            </b-button>
           
            <b-button
              class="btn"
              variant="primary"
              v-if="showUpdateButton"
              :disabled="disableUpdateButton"
              @click="updateOrder(updateObj)"
            >
              Update Order
            </b-button>
            
            <b-button
              v-b-tooltip.hover
              :title="localOrder.course_status ? '' : 'Please enter Course Grade, Completion Date and Grade Submission Type to mark this course graded.'"
              class="btn"
              :variant="localOrder.course_status ? 'warning' : 'primary'"
              v-if="showGradeButton"
              :disabled="disableGradeButton"
              @click="localOrder.course_status ? unGrade() : updateGradeOrder(updateObj)"
            >
              {{ localOrder.course_status ? 'Ungrade' : 'Grade' }}
            </b-button>
          
          <b-button
            variant="success"
            class="btn"
            @click="processSelectedOrder()"
            :disabled="processButtonDisabled"
            v-if="showProcessButton"
            style="width: 100%"
          >
            Process Order
          </b-button>
         
          <b-button
            variant="success"
            class="btn"
            @click="validateSelectedOrder()"
            :disabled="localOrder.duplicate_status"
            v-if="!localOrder.validation_status && checkIfAllowed(['admin','partner'])"
            style="width: 100%"
          >
            Validate Order
          </b-button>
          <b-button
            class="btn"
            variant="success"
            style="width: 100%"
            @click="enterBanner()"
            v-if="showEnterBannerButton"
          >
            Enter Registration into Banner
          </b-button>
          <b-button
            variant="success"
            class="btn"
            :disabled="localOrder.duplicate_status"
            v-if="showBannerGradeButton"
            style="width: 100%"
            @click="enterGradeToBanner"
          >
            Enter Grade into Banner
          </b-button>
          <b-button
            variant="info"
            class="btn"
            style="width: 100%"
            v-if="showLdProgress"
            @click="viewCourseAssignments"
          >
            LD Course Progress
            ({{ localOrder.ld_assignments ? localOrder.ld_assignments.length : 0}})
          </b-button>
          <b-button
            variant="primary"
            class="btn fakeButton"
            style="width: 100%"
            v-if="checkIfAllowed( ['admin', 'partner']) && this.localOrder.wc_order_id !== null && shouldDisplayViewDocumentation"
            :href="`https://teacherfriendly.com/ssworderdownloadQr89fGl/${this.localOrder.wc_order_id}`"
            target="_blank"
            @click="downloadDoc"
            :disabled="this.stages.downloadDoc === 'processing'"
          >
             Download Documentation 
            <b-spinner
              style="width: 20px; height: 20px"
              v-if="this.stages.downloadDoc === 'processing'"
              label="Loading..."
            ></b-spinner>
          </b-button>
          <b-button
            class="btn"
            variant="warning"
            style="width: 100%"
            @click="refundOrder()"
            v-if="$store.state.user.can_refund && this.localOrder.order_status"
          >
            {{this.localOrder.refund_status ? 'Unmark' : 'Mark'}} as Refunded
          </b-button>
          <b-button
            class="btn"
            variant="warning"
            style="width: 100%"
            @click="sendResubmitOrder()"
            v-if="checkIfAllowed( [ 'admin' ]) && this.localOrder.uop_process_failed && this.localOrder.wc_order_id"
          >
            Send Resubmit Email
          </b-button>
        </div>
      </div>
      <div class="inspect-right">
        <div class="header">
          <div class="title">
            <h4>Student Details</h4>
          </div>
          <div>
            <v-icon
                v-if="this.localOrder.clickUpTaskId && this.$store.state.user.can_view_ld"
                id="openClickUpTask"
                class="mr-2"
                style="width: 1.3rem; height: 1.3rem; opacity: 0.5;"
                name="list"
                @click="clickOrderLink('clickUp')"
            ></v-icon>
            <b-tooltip v-if="this.localOrder.clickUpTaskId && this.$store.state.user.can_view_ld" target="openClickUpTask" triggers="hover">
              Open ClickUp task in new tab
            </b-tooltip>
            <v-icon
                id="copyDirectLinkUC"
                class="mr-2"
                style="width: 1.3rem; height: 1.3rem; opacity: 0.5;"
                name="link"
                @click="clickOrderLink('UC')"
            ></v-icon>
            <b-tooltip target="copyDirectLinkUC" triggers="hover">
              Copy direct dashboard link to clipboard
            </b-tooltip>
            <v-icon
                v-if="this.localOrder.wc_order_id && $store.state.user.user_role == 'admin'"
                id="openTfOrder"
                class="mr-2"
                style="width: 1.3rem; height: 1.3rem; opacity: 0.5;"
                name="store"
                @click="clickOrderLink('TF')"
            ></v-icon>
            <b-tooltip v-if="this.localOrder.wc_order_id && $store.state.user.user_role == 'admin'" target="openTfOrder" triggers="hover">
              Open WooCommerce order in new tab
            </b-tooltip>
            <v-icon
                v-if="!this.$route.query.objectId"
                id="filterByCustomer"
                style="width: 1.3rem; height: 1.3rem; opacity: 0.5;"
                name="user"
                @click="filterByCustomer()"
            ></v-icon>
            <b-tooltip target="filterByCustomer" triggers="hover">
              Filter by Customer
            </b-tooltip>

          </div>

        </div>
        <details-table :order="order" @updateOrder="updateOrder" />
      </div>
    </div>
  </div>
</template>

<script>
const DetailsTable = () => import("./DetailsTable.vue");
import ENUMS from '@/helpers/constants' 
export default {
  props: {
    order: {},
    selectedView:{}
  },
  data() {
    return {
      localOrder: {
        course: {},
      },
      shouldDisplayViewDocumentation: false,
      stages: {
        downloadDoc: ""
      },
      selected: "",
      selectedCourseId: "",
      formatted: "",
      VIEWS: []
    };
  },
  methods: {
    filterByCustomer() {
      //console.log("filterByCustomer", this.$parent.selectedCustomerId, this.localOrder.customer)
      this.$parent.selectedCustomerId = this.localOrder.customer._id
    },
    clickOrderLink(site) {
      var url
      if(site == 'UC') {
        url = window.location.href.split(/[?#]/)[0]+"?objectId="+this.order._id
        navigator.clipboard.writeText(url);
        this.makeToast({
          variant: "success",
          message:
            "Direct link copied to your clipboard",
        });
      } else if(site == 'TF') {
        url = "https://teacherfriendly.com/wp-admin/post.php?post="+this.order.wc_order_id+"&action=edit"
        window.open(url, '_blank');
      } else if(site == 'clickUp') {
        url = "https://app.clickup.com/t/"+this.order.clickUpTaskId
        window.open(url, '_blank');
      }
      
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx;
    },
    async setSelectedOrder() {
      if (!this.order) return;
      await this.$store
      .dispatch("fetchCourses", this.order.partner_id)
      .then(() => {
        //console.log("resCourses", res);
       
      })
      .catch(() => {
        // console.log("errCourses", err);
      });
      this.setLocalOrder(this.order);
      this.setSelectedCourseId(this.order);
      // Fetch Teacher Friendly upload endpoint in the background 
      this.checkDocumentsEndpoint();

    },
    deleteSelectedOrder() {
      this.$emit("deleteSelectedOrder", "single");
    },
    processSelectedOrder() {
      this.$emit("processSelectedOrder", "single");
    },
    validateSelectedOrder() {
      this.$emit("validateSelectedOrder", 'single');
    },
    showNotesSelectedOrder() {
      this.$emit("showNotesSelectedOrder", 'single');
    },
    updateOrder(updatedOrder) {
      this.$emit("updateOrder", updatedOrder);
    },
    unGrade() {
      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.order._id+"/unGrade"
        })
        .then((res) => {
          console.log(res.payload)
          this.$parent.makeToast({ message: res.payload.message, variant: "success" });
          this.$parent.changeView();
        })
        .catch((err) => {
          this.$parent.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    updateGradeOrder(updatedOrder) {
      this.$emit("updateGradeOrder", updatedOrder);
    },
    enterBanner() {
      this.$emit("enterBanner", 'single');
    },
    enterGradeToBanner() {
      this.$emit("enterGradeToBanner", 'single');
    },
    refundOrder() {
      this.$emit("refundOrder", 'single');
    },
    sendResubmitOrder() {
      this.$emit("sendResubmitOrder", 'single');
    },
    async downloadDoc() {
      //Fake a loading time with sleep
      const sleep = ms => new Promise(r => setTimeout(r, ms));
      this.stages.downloadDoc = "processing";
      await sleep(5000)
      this.stages.downloadDoc = ""
    },
    viewCourseAssignments() {
      this.$emit("viewCourseAssignments", this.order.id);
    },
    processOrder() {
      this.$emit("processOrder", this.order.id);
    },
    setLocalOrder(newOrder) {
      if (!newOrder) return;
      this.localOrder = JSON.parse(JSON.stringify(newOrder));
      //console.log("localCustomer", this.localOrder.customer.uop_student_id)
    },
    setSelectedCourseId(newOrder) {
      if (!newOrder.course) return;
      this.selectedCourseId = newOrder.course._id;
    },
    checkIfAllowed(payload = []) {
      const userRole =
        this.$store.state.user && this.$store.state.user.user_role;
      return payload.includes(userRole);
    },
    checkIfPartnerMatch(payload) {
      const userRole =
        this.$store.state.user && this.$store.state.user.user_role;
      const partners =
        this.$store.state.user.partners && this.$store.state.user.partners;
      let result = false;
      if (
        partners &&
        partners.length &&
        payload.length &&
        userRole === "partner"
      ) {
        result = partners.some((each) => payload.includes(each));
      }

      return result;
    },
    checkIfCurrentViewMatch(payload=[]){
      if (payload.includes(this.selectedView)) return true;
      return false;
    },
    checkDocumentsEndpoint(){
      if(this.localOrder.wc_order_id){
        fetch('https://teacherfriendly.com/ssworderdownloadQr89fGl/'+this.localOrder.wc_order_id+'/check/').then((res) =>{
          if(res.status && res.status == 200) return;
          throw new Error('No Document Found');
        }).then(() => {
          this.shouldDisplayViewDocumentation = true
        })
        .catch(() => {
          this.shouldDisplayViewDocumentation = false
         
        });
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })
    },
  },
  computed: {
    emailCount() {
      const count = (this.$store.state.user.can_view_messages && this.localOrder.messages ? this.localOrder.messages.length : 0)+
                    (this.$store.state.user.can_view_templates && this.localOrder.workflow_logs ? this.localOrder.workflow_logs.length : 0);
      return (count > 0) ? '('+count+')' : '';
    },
    canLetterGrade() {
      return !this.localOrder.course.grade_mode || this.localOrder.course.grade_mode == 'letter';
    },
    canPassFailGrade() {
      return !this.localOrder.course.grade_mode || this.localOrder.course.grade_mode == 'passFail';
    },
    cardType() {
      let textDisplay = 'Card Type: ';
      if(this.localOrder.vgs_card_type && this.localOrder.vgs_card_type !== '') {
        textDisplay += this.localOrder.vgs_card_type.toUpperCase();
      } else if (this.localOrder.partner_id && this.localOrder.partner_id == 'MT') {
        textDisplay += ENUMS.MT_CARD_TYPE;
      } else {
        textDisplay += 'N/A';
      }
      return textDisplay;
    },
    cashnetAndProcessed() {
      let textDisplay = '';
      if(this.localOrder.order_status) {
        textDisplay += `Cashnet ID: ${(this.localOrder.cashnet_transactions && this.localOrder.cashnet_transactions.length > 0) ? this.localOrder.cashnet_transactions.map(e => e.id).join(', ') : "N/A"}`
        textDisplay += ` / Processed: ${this.formatDate(this.localOrder.uop_processed_date)}`
      }
      return textDisplay
    },
    courses() {
      return this.$store.state.courses ?? false;
    },
    creditFeeOptions() {
      const course = this.localOrder.course;
      const creditFeeOptions = [];
      if (!course) return creditFeeOptions;
      //Push all the credit options but check if they exist first
      if (course.credit_fee) creditFeeOptions.push(course.credit_fee);
      if (course.discounted_credit_fee)
        creditFeeOptions.push(course.discounted_credit_fee);
      if (course.special_credit_fee)
        creditFeeOptions.push(course.special_credit_fee);
      if (course.school_district_credit_fee)
        creditFeeOptions.push(course.school_district_credit_fee);
      //Return the array of options
      return creditFeeOptions;
    },
    totalCreditFee() {
      if (!this.localOrder.course) return 0;
      return (
        (this.localOrder.course_credits ?? 0) *
          (this.localOrder.course_credit_fee ?? 0) +
        (this.localOrder.course_fixed_credit_fee ?? 0)
      );
    },
    relatedOrders() {
      console.log(this.localOrder)
      return this.$store.state.relatedOrders;
    },
    anyChangesMade() {
      const localOrder = this.localOrder;
      const order = this.order;
      //console.log('DIF', localOrder.course.course_title, order.course.course_title)
      //console.log(JSON.stringify(order))
      return JSON.stringify(localOrder) !== JSON.stringify(order);
    },
    showDeleteButton() {
      const userRole = this.$store.state.user.user_role;
      if(this.localOrder.order_status) return false;
      if (userRole === "uop_admin") return false;
      if (userRole === "admin" || userRole === "partner") return true;
      return false;
    },
    disableDeleteButton() {
      const userRole = this.$store.state.user.user_role;
      if (userRole === "admin") return false;
      if (userRole === "partner" && !this.localOrder.order_status) return false;
      return true;
    },
    disableUpdateButton() {
      return (
        !this.localOrder.course_credits ||
        (!this.localOrder.course_credit_fee &&
          !this.localOrder.course_fixed_credit_fee)
      );
    },
    disableGradeButton() {
      return (
        !this.localOrder.course_credits ||
        (!this.localOrder.course_credit_fee &&
          !this.localOrder.course_fixed_credit_fee) ||
        (
          this.localOrder.grade_type == null ||
          this.localOrder.course_completion_date == null ||
          this.localOrder.course_grade == ""
        )
      );
    },
    showButtonSection() {
      return !(this.$route.query && this.$route.query.objectId && this.localOrder.deleted_status) && 
             !this.checkIfCurrentViewMatch([this.VIEWS.DELETED])
    },
    showUpdateButton() {
      return (
        this.checkIfAllowed(['admin', 'uop_admin']) || ( 
        !this.localOrder.course_status &&
        !this.localOrder.order_status )
      );
    },
    showGradeButton() {
      return (
        ( 
          (!this.localOrder.course_status) &&
          this.$store.state.user.user_role !== "uop_admin" &&
          this.localOrder.order_status 
        ) ||
        (
          this.localOrder.course_status &&
          this.$store.state.user.can_ungrade
        )

      );
    },
    showProcessButton() {
      return (this.$store.state.user && this.$store.state.user.can_process) &&
             (!this.localOrder.deleted_status && !this.localOrder.refund_status) &&
             (!this.localOrder.order_status && this.localOrder.validation_status);
    },
    showEnterBannerButton() {
      if (this.$store.state.user.user_role == "partner") return false;
      return (
        this.localOrder.order_status && this.localOrder.banner_status == false
      );
    },
    showBannerGradeButton() {
      const userRole = this.$store.state.user.user_role;
      if (userRole !== "admin" && userRole !== "uop_admin") return false;
      //Return false if banner_status false or grade_status true or grade_type == false
      if (
        !this.localOrder.banner_status ||
        this.localOrder.grade_status ||
        this.localOrder.grade_type == false
      )
        return false;
      return true;
    },
    showLdProgress() {
      return (this.checkIfAllowed( ['admin']) || !!this.$store.state.user.can_view_ld) && 
             this.localOrder.ld_course_status && 
             this.localOrder.ld_course_status.length > 0
    },
    processButtonDisabled() {
      //TEMP DISABLE
      //return true;
      if (this.localOrder.duplicate_status) return true;
      return this.anyChangesMade;
    },
    deleteButtonDisabled() {
      if (this.localOrder.order_status) return true;
      return false;
    },
    updateObj() {
      const obj = {};
      const keys = Object.keys(this.order);
      // console.log(this.order);
      // console.log(keys);
      for (let k = 0; k < keys.length; k++) {
        // console.log({
        //   [`localOrder--${header}`]: this.localOrder[header],
        //   [`order--${header}`]: this.order[header],
        // });
        const header = keys[k];
        if (this.localOrder[header] !== this.order[header]) {
          if (header === "course") {
            if (
              this.localOrder.course.course_number !==
              this.order.course.course_number
            ) {
              obj.course = this.localOrder.course._id;
            }
            continue;
          }
          if (header === "ld_course_status") continue;
          if (header === "ld_assignments") continue;
          if (header === "credit_years") continue;
          if (header === "ld_course_status") continue;
          if (header === "cashnet_transactions") continue;
          if (header === "cashnet_responses") continue;
          if (header === "ld_coursework_submitted_dates") continue;
          if (header === "workflow_logs") continue;
          if (header === "admin_notes") continue;
          if (header === "messages") continue;
          if (header === "resubmit_emails") continue;
          if (header === "course_completion_date") {
            obj[header] = this.localOrder[header] + " 00:00:00 PST";
            continue;
          }

          obj[header] = this.localOrder[header];
          // console.log(
          //   header +
          //     ": " +
          //     this.localOrder[header] +
          //     " doesnt equal " +
          //     this.order[header]
          // );
        }
      }
      console.log(obj)
      return { _id: this.localOrder._id, ...obj };
    },
  },
  watch: {
    order: {
      async handler() {
        await this.setSelectedOrder();
      },
      deep: true,
    },
    selectedCourseId(courseId) {
      this.localOrder.course = this.courses.find(
        (course) => course._id === courseId
      );
      if (this.localOrder.course) {
        // console.log({creditOptions: this.localOrder.course.credit_options})
        //Check if course credits is still a valid option
        if (
          !this.localOrder.course.credit_options.includes(
            this.localOrder.course_credits
          )
        ) {
          //If not clear it
          this.localOrder.course_credits = null;
        }
      }
      //Check if credit fee is still a valid option
      if (this.localOrder.course && this.localOrder.course.fixed_credit_fee) {
        //Check if fixed fee is the same as the new course
        if (
          this.localOrder.course_fixed_credit_fee !==
          this.localOrder.course.fixed_credit_fee
        ) {
          //If not make em equal
          this.localOrder.course_fixed_credit_fee =
            this.localOrder.course.fixed_credit_fee;
        }
      } else {
        //Check if credit fee options include credit fee
        if (
          !this.creditFeeOptions.includes(this.localOrder.course_credit_fee)
        ) {
          //If not clear it
          this.localOrder.course_credit_fee = null;
        }
      }
    },
  },
  async created() {
    this.VIEWS = ENUMS.VIEWS
    await this.setSelectedOrder();
  },
  components: {
    DetailsTable,
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
}
.dateBubbleContainer {
  align-self: flex-start;
  margin-bottom: 1rem;
  .dateBubble {
    display: inline-block;
    border-radius: 10px;
    box-shadow: 2px 5px 10px #ced4da;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    background-color: $gray-100;
    padding: 0.1rem 0.5rem;
    color: black;
  }
  .dateBubble:last-of-type {
    margin-right: 0;
  }
}

.inspect-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 460px;
  max-width: 460px;
  font-size: 1rem;
}
@media screen and (max-width: 870px) {
  .inspect-left {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .inspect-right {
    padding: 0 !important;
  }
}

.inspect-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 50px 30px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .title h4 {
    margin-bottom: 0;
  }
}
.subHeader {
  font-size: 0.875rem;
  color: black;
  border-radius: 10px;
  background-color: $gray-100;
}

.row {
  display: flex;
  flex-direction: row !important;
}

input:disabled,
select:disabled,
.b-form-datepicker[aria-disabled="true"] {
  opacity: 1;
  color: black;
  background-color: rgba(230, 230, 230, 0.3) !important;
}

.inspect-fields {
  .row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px 0;
    align-items: center;
  }
  .inputFieldsSize {
    width: 300px;
    height: 32px;
  }
}
.actionContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  .flex1 {
    flex: 1;
  }
}
.button-section {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  /* Make last button full width when # of buttons is Odd
  button:last-child:nth-child(2n - 1) {
    grid-column: span 2;
  }
  */
}
button, .fakeButton {
  color: white;
  display: flex;
  padding: 0.5rem 0.25rem;
  min-width: 10rem;
  justify-content: center;
  align-items: center;
}
</style>